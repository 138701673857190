<template>
  <v-dialog v-model="dialog" max-width="500px" persistent>
    <v-card>
      <v-card color="primary" flat>
        <v-card-title>
          <span class="headline white--text">{{ formTitle }}</span>
          <v-spacer></v-spacer>
          <v-btn
            fab
            class="ma-0 pa-0"
            small
            color="white black--text "
            @click="close"
          >
            <v-icon medium>close</v-icon>
          </v-btn>
        </v-card-title>
      </v-card>
      <v-card-text>
        <v-form>

          <v-text-field class="mb-2"
           
            name="title"
            label="Title"
            v-model="getSchema.title"
            v-validate="'required|max:100'"
            data-vv-name="title"
            :error-messages="errors.collect('title')"
            prepend-icon="edit"
          ></v-text-field>

          <v-menu
            v-model="ff_start_date_status"
            :close-on-content-click="false"
            :nudge-right="40"
            lazy
            transition="scale-transition"
            offset-y
            full-width
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                style="padding: 0px 10px"
                v-model="getSchema.ff_start_date"
                label="College Confirm Start Date"
                prepend-icon="event"
                readonly
                v-validate="'required'"
                data-vv-name="ff_start_date"
                :error-messages="errors.collect('ff_start_date')"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="getSchema.ff_start_date"
              @input="ff_start_date_status = false"
            ></v-date-picker>
          </v-menu>
          <v-menu
            v-model="ff_end_date_status"
            :close-on-content-click="false"
            :nudge-right="40"
            lazy
            transition="scale-transition"
            offset-y
            full-width
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                style="padding: 0px 10px"
                v-model="getSchema.ff_end_date"
                label="College Confirm End Date"
                prepend-icon="event"
                readonly
                v-validate="'required'"
                data-vv-name="ff_end_date"
                :error-messages="errors.collect('ff_end_date')"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="getSchema.ff_end_date"
              @input="ff_end_date_status = false"
            ></v-date-picker>
          </v-menu>
          <v-text-field
            name="no_of_installment"
            label="Installment"
            type="number"
            min="1"
            v-model="getSchema.no_of_installment"
            v-validate="'required'"
            data-vv-name="no_of_installment"
            :error-messages="errors.collect('no_of_installment')"
            prepend-icon="edit"
          ></v-text-field>

          <v-checkbox
            v-model="getSchema.status"
            value="1"
            label="Active"
            type="checkbox"
            color="indigo darken-3"
          ></v-checkbox>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-layout justify-center>
          <v-btn
            color="primary  white--text"
            @click.stop.prevent="validationForm()"
            >{{ buttonTitle }}</v-btn
          >
          <v-btn color="error  white--text" @click="clear">Reset</v-btn>
        </v-layout>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ["dialogStatus", "indexEdited"],

  $_veeValidate: {
    validator: "new",
  },

  data: () => ({
    ff_start_date_status: false,
    ff_end_date_status: false,
    dialog: false,
    editedStatus: false,
  }),

  watch: {
    dialogStatus(val) {
      this.dialog = val;
      // if(this.indexEdited>=0){

      //   this.$store.commit("SET_PAYMENT_INSTALLMENT_FORM_SCHEMA", this.editedData);
      // }else{
      //   this.$store.commit('UNSET_PAYMENT_INSTALLMENT_FORM_SCHEMA')
      // }
    },
    dialog(val) {
      if (!val) this.close();
    },
  },
  computed: {
    getSchema() {
      return this.$store.getters.getPaymentInstallmentSchema;
    },
    buttonTitle() {
      return this.indexEdited === -1 ? "Submit" : "Edit";
    },
    formTitle() {
      return this.indexEdited === -1
        ? "Create Payment Installment"
        : "Edit Payment Installment";
    },
    getLoggedInExam() {
      return this.$store.getters.getLoggedInExam;
    },
    getLoggedInYear() {
      return this.$store.getters.getLoggedInYear;
    },
    getExamInitiateId() {
      return this.$store.getters.getExamInitiateId;
    },
  },
  methods: {
    validationForm() {
      this.$validator.validateAll().then((isValid) => {
        if (isValid) {
          this.indexEdited === -1 ? this.save() : this.edit();
        }
      });
    },
    save() {
      this.$parent.$parent.initSearchParam();
      let self = this;
      let item = this.getSchema;
      this.$store
        .dispatch("storePaymentInstallment", item)
        .then(function (response) {
          self.close();
        })
        .catch(function (error) {
          console.log(error);
        })
        .then(function (always) {
          self.$parent.$parent.getSearchApi();
        });
    },
    edit() {
      let self = this;
      let item = this.getSchema;
      this.$store
        .dispatch("updatePaymentInstallment", item)
        .then(function (data) {
          self.close();
        })
        .catch(function (error) {
          console.log(error);
        })
        .then(function (always) {
          self.$parent.$parent.getSearchApi();
        });
    },
    clear() {
      this.$validator.reset();
    },
    close() {
      this.$emit("update:dialogStatus", false);
      setTimeout(() => {
        this.$emit("update:indexEdited", -1);
      }, 300);
      // this.clear();
    },
  },
  updated() {},
  mounted() {
    this.$store.commit(
      "UNSET_PAYMENT_INSTALLMENT_FORM_SCHEMA",
      this.$parent.$parent.searchParam
    );
    this.$parent.$parent.initSearchParam();
    this.getSchema.exam_code = this.getLoggedInExam.code;
    this.getSchema.exam_year = this.getLoggedInYear.year;
    this.getSchema.exam_initiate_id = this.getExamInitiateId;
  },
};
</script>

<style scoped></style>
