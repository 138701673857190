<template>
  <v-container fluid>
    <v-container fluid>
      <v-card color="white">
        <v-layout row>
          <v-card flat>
            <v-badge left overlap>
              <template v-slot:badge>
                <v-btn
                  round
                  color="primary"
                  fab
                  small
                  style="margin-top: -1.5em;"
                  class="elevation-0"
                  v-if="pagination.totalItems"
                >{{ pagination.totalItems }}</v-btn>
              </template>
            </v-badge>
          </v-card>
          <v-divider class="my-2" vertical></v-divider>
        </v-layout>
      </v-card>
      <div>
        <v-data-table
          :loading="loading"
          :headers="header"
          :items="getSchema"
          class="elevation-1"
          :total-items="pagination.totalItems"
          :pagination.sync="pagination"
          :rows-per-page-items="pagination.rowsPerPageItems"
        >
          <template v-slot:items="props">
            <tr>
              <td>{{ props.index + sl_count }}</td>
              <td class="text-xs">{{ props.item.college_code }} - {{ props.item.college_name }}</td>
              <td class="text-xs">{{ props.item.transaction_id }}</td>
              <td class="text-xs">{{ props.item.no_of_candidates }}</td>
            </tr>
          </template>
        </v-data-table>
      </div>
      <!-- <pre>{{getSchema}}</pre> -->
    </v-container>
  </v-container>
</template>
<script>
import commonGet from "@/mixin/common_getters_mixin";
export default {
  mixins: [commonGet],
  data() {
    return {
      formDialog: true,
      loading: false,
      pagination: {
        rowsPerPage: 10,
        rowsPerPageItems: [10, 25, { text: "All", value: -1 }],
      },
      sl_count: 1,
      header: [
        { text: "SL.", sortable: false, value: "id", width: "1%" },
        { text: "College Code ", value: "college_code", sortable: false },
        { text: "Transaction Id", value: "transaction_id", sortable: false },
        { text: "Total Candidates ", value: "no_of_candidates", sortable: false },
      ]
    };
  },
  created() {

  },
  computed: {
    getSchema(){
      return this.$store.getters.getPaymentInstallmentDetails;
    }
  },
  methods: {
    setLimit() {
      const { sortBy, descending, page, rowsPerPage } = this.pagination;
      this.searchParam.page = page;
      this.searchParam.limit = rowsPerPage;
      this.searchParam.sort = sortBy;
      this.searchParam.search = this.search;
    },
    close() {
      this.$emit("update:installmentDialogStatus", false);
    },
  },
  watch: {
  },
};
</script>
<style scoped>
.mycustomExpansionPanel .v-expansion-panel__header {
  border: 1px solid rgb(255, 255, 255) !important;
}

.Male {
  background-color: #386c8e;
  color: white;
}
.Female {
  background-color: #ad2da3;
  color: white;
}
</style>
